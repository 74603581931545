import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase/config';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      if (!userCredential.user.emailVerified) {
        await sendEmailVerification(userCredential.user);
        await auth.signOut();
        navigate('/verify-reminder');
        return;
      }

      // Get all clinics
      const clinicsRef = collection(db, 'clinics');
      const clinicsSnapshot = await getDocs(clinicsRef);
      let userClinic = null;
      let userClinicId = null;

      // Check each clinic's users subcollection for the current user
      for (const clinic of clinicsSnapshot.docs) {
        const userRef = doc(db, `clinics/${clinic.id}/users/${userCredential.user.uid}`);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          userClinic = clinic.data();
          userClinicId = clinic.id;
          break;
        }
      }

      if (userClinic) {
        if (userClinic.subscriptionActive === false) {
          // Redirect to complete setup with necessary parameters
          const params = new URLSearchParams({
            step: '2',
            clinicId: userClinicId,
            userId: userCredential.user.uid,
            email: userCredential.user.email
          });
          navigate(`/complete-setup?${params.toString()}`);
        } else {
          // Subscription is active, redirect to app
          window.location.href = 'https://app.lyravet.com';
        }
      } else {
        setError('No clinic found for this user.');
      }

    } catch (error) {
      console.log('Detailed error:', error);
      switch (error.code) {
        case 'auth/invalid-email':
          setError('Invalid email address format.');
          break;
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          setError('Invalid email or password.');
          break;
        case 'auth/too-many-requests':
          setError('Too many failed login attempts. Please try again later.');
          break;
        default:
          setError(`An error occurred during login: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[400px] mx-auto pt-32 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-8 shadow-xl border border-gray-200/20">
              <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-800">Update Your Account</h1>
                <p className="text-gray-600 mt-2">Sign in to continue your journey with us by activating your account</p>
              </div>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                             focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                             placeholder-gray-400 transition-colors"
                    placeholder="Enter your email"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                             focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                             placeholder-gray-400 transition-colors"
                    placeholder="Enter your password"
                  />
                </div>

                {error && (
                  <div className="text-red-500 text-sm text-center">
                    {error}
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-[#00B8D4] border-gray-300 rounded"
                    />
                    <label className="ml-2 text-sm text-gray-700">Remember me</label>
                  </div>
                  <Link to="/forgot-password" className="text-sm text-[#00B8D4] hover:text-[#008CA1]">
                    Forgot password?
                  </Link>
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-[#00B8D4] text-white px-6 py-3 rounded-lg 
                           hover:bg-[#008CA1] transition-colors text-base font-medium
                           disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  {loading ? 'Signing in...' : 'Sign in'}
                </button>
              </form>

              <div className="mt-6 text-center text-sm text-gray-600">
                Don't have an account?{' '}
                <Link to="/signup" className="text-[#00B8D4] hover:text-[#008CA1] font-medium">
                  Sign up
                </Link>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default LoginPage;

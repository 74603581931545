import React from 'react';

export const Pricing = () => {
  const plans = [
    {
      name: 'Non-Integrated',
      size: 'Individual Plan',
      price: '$110',
      period: 'per month',
      priceTagline: 'Starting at',
      features: [
        'Manual Patient Entry for Scribe Use',
        'Access to Chat',
        'Automated Task List',
        '1 DVM',
        'No Integration Required'
      ],
      valueProps: [
        'Simple setup with no integration',
        'Core features for individual use',
        'Affordable entry point'
      ]
    },
    {
      name: 'Integrated',
      size: 'Team or Individual Plans Available',
      price: '$150',
      period: 'per month',
      priceTagline: 'Starting at',
      features: [
        'Automatic Patient Data Import',
        'Appointment Primers',
        'Patient-Specific Clinical Support Tools',
        'Access to Chat',
        'Automated Task List',
        'Variable Cost Based on Team Size'
      ],
      valueProps: [
        'Seamless practice integration',
        'Complete feature access',
        'Scales with your team'
      ],
      popular: true
    },
    {
      name: 'Enterprise',
      size: 'Large Institutions',
      price: 'Custom',
      period: 'pricing',
      priceTagline: '',
      features: [
        'Options for Integration or Non-Integrated',
        'Custom Built Features',
        'Tailored to Your Team\'s Needs',
        'Priority Support',
        'All Features from Integrated Plan',
      ],
      valueProps: [
        'Built for large multi-doctor practices',
        'Customizable to your workflow',
        'Premium support and services'
      ]
    }
  ];

  return (
    <section className="py-16 w-[90%] max-w-7xl mx-auto" id="pricing">
      <div className="text-center mb-12">
        <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-deep-space">Simple, Flexible Plans</h2>
        <p className="text-lg sm:text-xl text-gray-600">Choose the option that fits your practice</p>
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 px-4">
        {plans.map((plan) => (
          <div 
            key={plan.name} 
            className={`
              bg-white rounded-xl p-6  
              shadow-sm border border-gray-100
              hover:border-[#00B8D4]/30 transition-all
              relative
              ${plan.popular ? 'ring-2 ring-[#00B8D4] relative scale-[1.02]' : ''}
            `}
          >
            {plan.popular && (
              <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                <span className="bg-[#00B8D4] text-white text-sm font-medium px-3 py-1 rounded-full">
                  Most Popular
                </span>
              </div>
            )}

            <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
            <p className="text-gray-500 mb-4">{plan.size}</p>
            
            {/* Price */}
            <div className="mb-6 text-center">
              {plan.priceTagline && <span className="text-sm text-gray-500">{plan.priceTagline}</span>}
              <div className="flex items-center justify-center">
                <span className="text-3xl font-bold text-deep-space">{plan.price}</span>
                <span className="text-gray-500 ml-1">{plan.period}</span>
              </div>
            </div>
            
            {/* Value Props */}
            <div className="mb-6 bg-[#00B8D4]/5 rounded-lg p-4">
              {plan.valueProps.map((prop, index) => (
                <p key={index} className="text-sm text-gray-700 mb-2 flex items-center">
                  <span className="text-[#00B8D4] mr-2">★</span>
                  {prop}
                </p>
              ))}
            </div>

            {/* Features */}
            <ul className="space-y-3 mb-8">
              {plan.features.map((feature) => (
                <li key={feature} className="flex items-center text-gray-700 text-sm">
                  <svg className="w-4 h-4 mr-2 text-[#00B8D4]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            
            <div className="grid grid-cols-2 gap-3">
              <a 
                href="https://calendly.com/admin-lyravet/demo"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full py-2.5 px-4 bg-[#00B8D4] hover:bg-[#00A5BE] text-white rounded-lg font-medium transition-colors text-sm inline-block text-center"
              >
                Learn More
              </a>
              <a 
                href="/signup"
                className="w-full py-2.5 px-4 border border-[#00B8D4] text-[#00B8D4] hover:bg-[#00B8D4] hover:text-white rounded-lg font-medium transition-colors text-sm inline-block text-center"
              >
                Start Free Trial
              </a>
              <p className="text-red-400 text-sm text-center mt-4 col-span-2">
                2 Week Free Trial
              </p>
            </div>
          </div>
        ))}
      </div>

    </section>
  );
};

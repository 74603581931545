import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Navbar } from '../components/navigation/Navbar';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { auth } from '../firebase/config';
import { createUserWithEmailAndPassword, sendEmailVerification } from '@firebase/auth';
import { db } from '../firebase/config';
import { doc, setDoc, collection, writeBatch, getDoc } from '@firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51NEDchBQRI88GTVxMlp7NefC69fO61iMpBAbJnmRld7kRMzuXqVrRdaoo0ngAwgHGFRoeHBiYFEZKt7YBJA0jdwW00CUghgQOr'); // Replace with your Stripe publishable key

// Add this spinner component to the top of the component
const Spinner = () => (
  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);

const CompleteSetupPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  // Get URL parameters and log them
  const clinicId = searchParams.get('clinicId');
  const userId = searchParams.get('userId');
  const email = searchParams.get('email');
  const initialStep = parseInt(searchParams.get('step')) || 1;

  console.log('URL Parameters:', {
    clinicId,
    userId,
    email
  });

  // Update state initialization
  const [step, setStep] = useState(initialStep);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [fadeOut, setFadeOut] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    practiceName: '',
    email: '',
    password: '',
    confirmPassword: '',
    streetAddress: '',
    suite: '',
    city: '',
    postalCode: '',
    veterinarians: 1,
    termsAccepted: false,
    pimsProvider: '',
    promoCode: '',
    isIntegrated: true, // Default to integrated since this is for clinics
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // Add effect to fetch clinic data on component mount
  useEffect(() => {
    const fetchClinicData = async () => {
      if (!clinicId) return;

      try {
        const clinicDoc = await getDoc(doc(db, 'clinics', clinicId));
        
        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          
          // Pre-fill form data with existing clinic information
          setFormData(prev => ({
            ...prev,
            streetAddress: clinicData.address?.address || '',
            city: clinicData.address?.city || '',
            postalCode: clinicData.address?.zipcode || '',
            pimsProvider: clinicData.PIMs || '',
            veterinarians: clinicData.numberOfDoctors || 1,
          }));
          
          // Set country and region if they exist
          if (clinicData.address?.country) {
            setCountry(clinicData.address.country);
          }
          if (clinicData.address?.state) {
            setRegion(clinicData.address.state);
          }

          // Store clinicId in localStorage for later use
          localStorage.setItem('clinicId', clinicId);
        }
      } catch (error) {
        console.error('Error fetching clinic data:', error);
        setError(
          <span className="text-red-500 text-xs">
            Error loading clinic data. You can still proceed with setup.
          </span>
        );
      }
    };

    fetchClinicData();
  }, [clinicId]);

  // Add useEffect to get user email from auth
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    console.log('Starting checkout process');
    setError('');
    setLoading(true);

    try {
      if (!email) {
        throw new Error('No email address found');
      }

      // First save the demographic data
      await setDoc(doc(db, 'clinics', clinicId), {
        address: {
          address: formData.streetAddress,
          city: formData.city,
          country: country,
          state: region,
          zipcode: formData.postalCode
        },
        PIMs: formData.pimsProvider,
        numberOfDoctors: formData.veterinarians
      }, { merge: true });

      const subscriptionData = {
        userId,
        clinicId,
        numberOfUsers: formData.veterinarians,
        billingCycle,
        email: email,
        promoCode: formData.promoCode,
        isIntegrated: formData.isIntegrated
      };

      // Log the subscription data being sent
      console.log('Subscription data:', subscriptionData);

      const createCheckoutSession = httpsCallable(getFunctions(), 'createCheckoutSession');
      const result = await createCheckoutSession(subscriptionData);
      
      console.log('Got session result:', result);
      const { sessionId } = result.data;

      await setDoc(doc(db, 'clinics', clinicId), {
        latestCheckoutSession: sessionId
      }, { merge: true });

      console.log('About to redirect to Stripe');
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId
      });

      if (error) {
        throw error;
      }

    } catch (error) {
      console.error('Checkout error:', error);
      setError(
        <span className="text-red-500 text-xs">
          {error.message}
        </span>
      );
      setLoading(false);
    }
  };

  const getPricing = () => {
    const vets = formData.veterinarians;
    
    // For non-integrated plans
    if (!formData.isIntegrated) {
      if (billingCycle === 'monthly') {
        return (
          <div className="space-y-1">
            <div className="text-3xl font-bold">$130/month</div>
          </div>
        );
      } else {
        return (
          <div className="space-y-1">
            <div className="text-3xl font-bold">$110/month</div>
            <div className="text-base text-red-600 font-bold">
              Save $240/year
            </div>
          </div>
        );
      }
    }
    
    // For integrated plans
    if (vets >= 12) {
      return (
        <div className="space-y-2">
          <div>Contact us directly for discounted enterprise pricing</div>
          <div className="text-[#00B8D4]">support@lyravet.com</div>
        </div>
      );
    }

    if (billingCycle === 'monthly') {
      const price = 179 + (vets - 1) * 119;
      return (
        <div className="space-y-1">
          <div className="text-3xl font-bold">${price}/month</div>
          <div className="text-sm text-gray-600">
            First doctor: $179/month<br/>
            Each additional: $119/month
          </div>
        </div>
      );
    } else {
      // Annual pricing
      const price = 150 + (vets - 1) * 100;
      const annualTotal = price * 12;
      
      return (
        <div className="space-y-1">
          <div className="text-3xl font-bold">${price}/month</div>
          <div className="text-sm text-gray-600">
            First doctor: $150/month<br/>
            Each additional: $100/month
          </div>
          <div className="text-base text-red-600 font-bold">
            Billed as ${annualTotal}/year
          </div>
        </div>
      );
    }
  };

  return (
    <div className="relative">
      <div 
        className="fixed inset-0 bg-cover bg-center bg-no-repeat blur-sm"
        style={{ backgroundImage: 'url(/blurBackground.png)', filter: 'blur(8px)' }}
      ></div>
      <div className="fixed inset-0 bg-black/30"></div>
      <div className="relative min-h-screen flex flex-col">
        <main className="flex-1 flex items-center justify-center py-8 px-4">
          <div className="w-full max-w-[960px] bg-white/80 backdrop-blur-md rounded-lg p-6 shadow-xl border border-gray-200/20 max-h-[90vh] overflow-y-auto">
            <div className="text-center mb-6">
              <h1 className="text-2xl font-bold text-gray-800">Your LyraVet Adventure Awaits</h1>
              <p className="text-gray-600 mt-2">Sign in to continue your journey with us by activating your account</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Demographics Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-800">Practice Info</h2>
                <form onSubmit={handleCheckout} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Street Address
                    </label>
                    <input
                      type="text"
                      name="streetAddress"
                      value={formData.streetAddress}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter street address"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Country
                      </label>
                      <CountryDropdown
                        value={country}
                        onChange={(val) => setCountry(val)}
                        classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                placeholder-gray-400 transition-colors text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        State/Province
                      </label>
                      <RegionDropdown
                        country={country}
                        value={region}
                        onChange={(val) => setRegion(val)}
                        classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                placeholder-gray-400 transition-colors text-sm"
                        disableWhenEmpty={true}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter city"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Postal Code
                      </label>
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter postal code"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      PIMs/EMR Provider
                    </label>
                    <input
                      type="text"
                      name="pimsProvider"
                      value={formData.pimsProvider}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter your EMR/PIMs provider"
                    />
                  </div>
                </form>
              </div>

              {/* Plan Selection Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-800">Select Your Plan</h2>
                
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Subscription Type
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    <button
                      type="button"
                      onClick={() => setFormData({...formData, isIntegrated: false, veterinarians: 1})}
                      className={`p-4 rounded-lg border-2 transition-colors text-left
                                ${!formData.isIntegrated 
                                  ? 'border-[#00B8D4] bg-blue-50' 
                                  : 'border-gray-200 bg-white'}`}
                    >
                      <div className="font-semibold text-gray-800">Standard</div>
                      <div className="text-xs text-gray-600 mt-1">
                        For individual DVMs without PIMs integration
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => setFormData({...formData, isIntegrated: true})}
                      className={`p-4 rounded-lg border-2 transition-colors text-left
                                ${formData.isIntegrated 
                                  ? 'border-[#00B8D4] bg-blue-50' 
                                  : 'border-gray-200 bg-white'}`}
                    >
                      <div className="font-semibold text-gray-800">Integrated</div>
                      <div className="text-xs text-gray-600 mt-1">
                        For practices with PIMs integration
                      </div>
                    </button>
                  </div>
                </div>

                {formData.isIntegrated && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      How many users will be using LyraVet?
                    </label>
                    <input
                      type="range"
                      min="1"
                      max="12"
                      name="veterinarians"
                      value={formData.veterinarians}
                      onChange={handleInputChange}
                      className="w-full h-2 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-[#00B8D4]"
                    />
                    <div className="flex justify-between text-xs text-gray-600 mt-1">
                      <span>1</span>
                      <span>12+</span>
                    </div>
                    <div className="text-center text-sm text-gray-600 mt-1">
                      Selected: {formData.veterinarians === 12 ? '12+' : formData.veterinarians} user{formData.veterinarians > 1 ? 's' : ''}
                    </div>
                  </div>
                )}

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Billing Cycle
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    <button
                      type="button"
                      onClick={() => setBillingCycle('monthly')}
                      className={`p-4 rounded-lg border-2 transition-colors text-left
                                ${billingCycle === 'monthly' 
                                  ? 'border-[#00B8D4] bg-blue-50' 
                                  : 'border-gray-200 bg-white'}`}
                    >
                      <div className="font-semibold text-gray-800">Monthly</div>
                      <div className="text-xs text-gray-600 mt-1">
                        Pay month-to-month
                      </div>
                      <div className="mt-2 font-bold text-gray-800">
                        {!formData.isIntegrated ? '$130/month' : 
                          formData.veterinarians >= 12 ? 'Custom pricing' :
                          `$${179 + (formData.veterinarians - 1) * 119}/month`}
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => setBillingCycle('annual')}
                      className={`p-4 rounded-lg border-2 transition-colors text-left
                                ${billingCycle === 'annual' 
                                  ? 'border-[#00B8D4] bg-blue-50' 
                                  : 'border-gray-200 bg-white'}`}
                    >
                      <div className="font-semibold text-gray-800">Annual</div>
                      <div className="text-xs text-gray-600 mt-1">
                        Save with yearly billing
                      </div>
                      <div className="mt-2 font-bold text-gray-800">
                        {!formData.isIntegrated ? '$110/month' : 
                          formData.veterinarians >= 12 ? 'Custom pricing' :
                          `$${150 + (formData.veterinarians - 1) * 100}/month`}
                      </div>
                      {!formData.isIntegrated && (
                        <div className="text-xs text-red-600 font-bold">
                          Save $240/year
                        </div>
                      )}
                      {formData.isIntegrated && formData.veterinarians < 12 && (
                        <div className="text-xs text-red-600 font-bold">
                          Save ~16%
                        </div>
                      )}
                    </button>
                  </div>
                </div>

                {error && (
                  <p className="text-red-500 text-sm text-center mt-2">
                    {error}
                  </p>
                )}
              </div>
            </div>

            {/* Submit button outside the grid and centered */}
            <div className="mt-8 flex justify-center">
              <button
                type="button"
                onClick={handleCheckout}
                disabled={loading || (formData.isIntegrated && formData.veterinarians >= 12)}
                className="w-1/3 bg-[#00B8D4] text-white py-3 rounded-lg 
                         hover:bg-[#008CA1] transition-colors text-sm font-medium
                         disabled:bg-gray-400 disabled:cursor-not-allowed flex justify-center items-center"
              >
                {loading ? (
                  <>
                    <Spinner />
                    <span>Processing...</span>
                  </>
                ) : (formData.isIntegrated && formData.veterinarians >= 12) ? 
                  'Contact Sales Team' : 'Continue to Checkout'}
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CompleteSetupPage;

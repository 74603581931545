import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { auth } from '../firebase/config';
import { createUserWithEmailAndPassword, sendEmailVerification } from '@firebase/auth';
import { db } from '../firebase/config';
import { doc, setDoc, collection, writeBatch, getDoc } from '@firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51NEDchBQRI88GTVxMlp7NefC69fO61iMpBAbJnmRld7kRMzuXqVrRdaoo0ngAwgHGFRoeHBiYFEZKt7YBJA0jdwW00CUghgQOr'); // Replace with your Stripe publishable key

const SignUpPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [fadeOut, setFadeOut] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    practiceName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    streetAddress: '',
    suite: '',
    city: '',
    postalCode: '',
    veterinarians: 1,
    termsAccepted: false,
    pimsProvider: '',
    isIntegrated: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 12,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    
    const failedRequirements = Object.entries(requirements)
      .filter(([_, passes]) => !passes)
      .map(([requirement]) => requirement);
    
    return {
      isValid: failedRequirements.length === 0,
      failedRequirements
    };
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    setError('');

    if (step === 1) {
      // Check required fields for step 1
      if (!formData.firstName || !formData.lastName || !formData.practiceName || !formData.email || !formData.password || !formData.confirmPassword || !formData.phoneNumber) {
        setError(
          <span className="text-red-500 text-xs">
            Please fill in all required fields
          </span>
        );
        return;
      }

      if (!formData.termsAccepted) {
        setError(
          <span className="text-red-500 text-xs">
            Please accept the terms and conditions
          </span>
        );
        return;
      }

      const { isValid, failedRequirements } = validatePassword(formData.password);
      if (!isValid) {
        const requirementMessages = {
          length: 'At least 12 characters',
          uppercase: 'Uppercase letters',
          lowercase: 'Lowercase letters',
          number: 'Numbers',
          special: 'Special characters (!@#$%^&*(),.?":{}|<>)'
        };

        const missingRequirements = failedRequirements.map(req => requirementMessages[req]);
        setError(
          <div className="text-red-500 text-xs mt-2">
            Password must contain:
            <ul className="list-disc list-inside space-y-1 mt-1">
              {missingRequirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>
        );
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        setError(
          <span className="text-red-500 text-xs">
            Passwords do not match
          </span>
        );
        return;
      }

      setLoading(true);
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        await sendEmailVerification(userCredential.user);

        const userId = userCredential.user.uid;
        const clinicRef = doc(collection(db, 'clinics'));
        const clinicId = clinicRef.id; // Get the generated clinic ID
        
        await setDoc(clinicRef, {
          practiceName: formData.practiceName,
          createdAt: new Date().toISOString(),
          owner: userId,
          admins: [userId],
          subscriptionActive: false,
          phoneNumber: formData.phoneNumber
        });

        const userDocRef = doc(db, 'clinics', clinicId, 'users', userId);
        await setDoc(
          userDocRef,
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            role: 'admin',
            createdAt: new Date().toISOString(),
          }
        );

        // --- Setup Default Scribe Templates and Settings ---
        const defaultTemplateIds = ["1-LyraVetSOAPNoteDefault", "1-LyraVetClientDischargeDefault"];
        const sourceCollectionPath = "social/scribe/communityTemplates";
        const targetTemplatesCollectionPath = `clinics/${clinicId}/users/${userId}/settings/scribeSettings/templates`;
        const scribeSettingsRef = doc(db, 'clinics', clinicId, 'users', userId, 'settings', 'scribeSettings');

        let soapNoteTemplateContent = "S:\nO:\nA:\nP:\n"; // Default fallback
        let soapNoteContext = ""; // Default fallback
        let dischargeTemplateContent = "Discharge instructions:\n"; // Default fallback
        let dischargeContext = 'Maintain a friendly, caring, and personable tone throughout. Use the pet\'s name frequently and express genuine interest in the pet\'s well-being.'; // Default fallback

        try {
          console.log("Fetching and setting up default scribe templates...");
          for (const templateId of defaultTemplateIds) {
            const sourceTemplateRef = doc(db, sourceCollectionPath, templateId);
            const templateDoc = await getDoc(sourceTemplateRef);

            if (templateDoc.exists()) {
              const sourceData = templateDoc.data();
              console.log(`Found default template: ${templateId}`);

              // Prepare data for the user's template copy
              const userTemplateData = {
                author: sourceData.author || 'LyraVet',
                authorId: sourceData.authorId || 'LyraVet',
                category: sourceData.category || (templateId.includes('SOAP') ? 'SOAP Note' : 'Client Discharge'),
                color: sourceData.color || '#4CD3E9',
                context: sourceData.context || '',
                createdAt: new Date(), // Use JS Date object
                description: sourceData.description || '',
                id: templateId, // Use the original ID
                isDefault: true,
                note: sourceData.note || '',
                title: sourceData.title || 'LyraVet\'s Community Note',
                type: sourceData.type || 'note',
                updatedAt: new Date() // Use JS Date object
              };

              // Set the user's copy of the template
              const targetTemplateRef = doc(db, targetTemplatesCollectionPath, templateId);
              await setDoc(targetTemplateRef, userTemplateData);
              console.log(`Copied template ${templateId} to user settings.`);

              // Store content and context for scribeSettings update
              if (templateId.includes('SOAP')) {
                soapNoteTemplateContent = userTemplateData.note;
                soapNoteContext = userTemplateData.context;
              } else if (templateId.includes('Discharge')) {
                dischargeTemplateContent = userTemplateData.note;
                dischargeContext = userTemplateData.context;
              }
            } else {
              console.warn(`Default template ${templateId} not found in ${sourceCollectionPath}. Using fallback content.`);
              // Fallback content is already defined above, so no action needed here
            }
          }

          // Prepare data for the scribeSettings document
          const scribeSettingsData = {
            personalSoapNoteTemplate: soapNoteTemplateContent,
            soapNoteAdditions: soapNoteContext || '', // Use fetched context or fallback
            personalDischargeTemplate: dischargeTemplateContent,
            clientDischargeAdditions: dischargeContext || 'Maintain a friendly, caring, and personable tone throughout. Use the pet\'s name frequently and express genuine interest in the pet\'s well-being.', // Use fetched context or fallback
            usingClientDischargeDefaultTemplate: false, // Set to false as we are providing personal templates now
            usingSoapNoteDefaultTemplate: false, // Set to false
            importedCommunityTemplates: [] // Initialize as empty array
          };

          // Set the scribeSettings document (this replaces any previous simple setDoc for scribeSettings)
          await setDoc(scribeSettingsRef, scribeSettingsData);
          console.log("Updated scribeSettings document with default template content.");

        } catch (templateError) {
          console.error("Error setting up default scribe templates:", templateError);
          // Log error but continue signup process, user can configure later if needed
        }
        // --- End Default Scribe Templates and Settings Setup ---

        // Add quickPicks settings document
        await setDoc(
          doc(db, 'clinics', clinicId, 'users', userId, 'settings', 'quickPicks'),
          {
            quickPicks: [
              {
                id: "1738116407996",
                label: "One of Kind!",
                question: "What is something unique about this patient I can use to build rapport with the owner."
              },
              {
                id: "1740405857289",
                label: "Major Issues",
                question: "Please give dates and summaries for the major medical issues this patient has experienced."
              }
            ]
          }
        );

        // Create a batch to add multiple appointment type documents
        const batch = writeBatch(db);

        // Define the appointment types
        const appointmentTypes = {
          "Dental": {
            color: "#66DCF5",
            subTypes: ["Dental"]
          },
          "Grooming": {
            color: "#B686FF",
            subTypes: ["Grooming"]
          },
          "Large Animal": {
            color: "#70E0A3",
            subTypes: ["Large Animal", "Haul in Large Animal", "Farm Call"]
          },
          "Recheck": {
            color: "#A8B9FF",
            subTypes: ["Recheck", "recheck"]
          },
          "Standard": {
            color: "#00B8D4",
            subTypes: ["Standard", "Cat appmnt", "Drop Off", "Sick Pet", "HOLD SLOT"]
          },
          "Surgical": {
            color: "#FFD84D",
            subTypes: ["Surgical"]
          },
          "Technician": {
            color: "#7DE2ED",
            subTypes: ["Technician", "TECH ONLY"]
          },
          "Urgent": {
            color: "#FF9E6C",
            subTypes: ["Urgent", "Euthanasia", "Emergency"]
          }
        };

        // Create a document for each appointment type
        Object.entries(appointmentTypes).forEach(([typeName, typeData]) => {
          const typeRef = doc(db, 'clinics', clinicId, 'users', userId, 'settings', 'missionBrief', 'appointmentTypes', typeName);
          batch.set(typeRef, typeData);
        });

        // Commit the batch
        await batch.commit();

        localStorage.setItem('clinicId', clinicId);
        
        setFadeOut(true);
        setTimeout(() => {
          setStep(prev => prev + 1);
          setFadeOut(false);
          setLoading(false);
        }, 300);
      } catch (error) {
        setError(
          <span className="text-red-500 text-xs">
            {error.message}
          </span>
        );
        setLoading(false);
      }
    } else if (step === 3) {
      // Check required fields for step 3
      if (!formData.streetAddress || !formData.city || !formData.postalCode || !formData.pimsProvider || !country || !region) {
        setError(
          <span className="text-red-500 text-xs">
            Please fill in all required fields
          </span>
        );
        return;
      }

      try {
        setLoading(true);
        const clinicId = localStorage.getItem('clinicId');
        
        await setDoc(doc(db, 'clinics', clinicId), {
          address: {
            address: formData.streetAddress,
            city: formData.city,
            country: country,
            state: region,
            zipcode: formData.postalCode
          },
          PIMs: formData.pimsProvider
        }, { merge: true });

        setFadeOut(true);
        setTimeout(() => {
          setStep(prev => prev + 1);
          setFadeOut(false);
        }, 300);
      } catch (error) {
        console.error('Error:', error);
        setError(
          <span className="text-red-500 text-xs">
            {error.message}
          </span>
        );
      } finally {
        setLoading(false);
      }
    } else if (step === 4) {
      try {
        setLoading(true);
        const userId = auth.currentUser?.uid;
        if (!userId) {
          throw new Error('No authenticated user found');
        }
        
        const clinicId = localStorage.getItem('clinicId');
        if (!clinicId) {
          throw new Error('No clinic ID found');
        }

        // Create the subscription data with updated parameters
        const subscriptionData = {
          userId,
          clinicId,
          numberOfUsers: formData.veterinarians,
          billingCycle,
          email: formData.email,
          promoCode: formData.promoCode,
          isIntegrated: formData.isIntegrated
        };

        // Call the Cloud Function using httpsCallable
        const createCheckoutSession = httpsCallable(getFunctions(), 'createCheckoutSession');
        const result = await createCheckoutSession(subscriptionData);
        
        // Get the sessionId from the result
        const { sessionId } = result.data;

        // Update clinic document with session ID for tracking
        await setDoc(doc(db, 'clinics', clinicId), {
          latestCheckoutSession: sessionId
        }, { merge: true });

        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId
        });

        if (error) {
          throw error;
        }

      } catch (error) {
        console.error('Error:', error);
        setError(
          <span className="text-red-500 text-xs">
            {error.message}
          </span>
        );
        setLoading(false);
      }
    } else {
      setFadeOut(true);
      setTimeout(() => {
        setStep(prev => prev + 1);
        setFadeOut(false);
      }, 300);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setFadeOut(true);
    setTimeout(() => {
      setStep(prev => prev - 1);
      setFadeOut(false);
    }, 300);
  };

  const getButtonText = () => {
    if (step === 1) {
      return loading ? 'Creating Account...' : 'Continue';
    }
    return 'Continue';
  };

  const getPricing = () => {
    const vets = formData.veterinarians;
    
    // For non-integrated plans
    if (!formData.isIntegrated) {
      if (billingCycle === 'monthly') {
        return (
          <div className="space-y-1">
            <div className="text-3xl font-bold">$130/month</div>
          </div>
        );
      } else {
        return (
          <div className="space-y-1">
            <div className="text-3xl font-bold">$110/month</div>
            <div className="text-base text-red-600 font-bold">
              Save $240/year
            </div>
          </div>
        );
      }
    }
    
    // For integrated plans
    if (vets >= 12) {
      return (
        <div className="space-y-2">
          <div>Contact us directly for discounted enterprise pricing</div>
          <div className="text-[#00B8D4]">support@lyravet.com</div>
        </div>
      );
    }

    if (billingCycle === 'monthly') {
      const price = 179 + (vets - 1) * 119;
      return (
        <div className="space-y-1">
          <div className="text-3xl font-bold">${price}/month</div>
          <div className="text-sm text-gray-600">
            First doctor: $179/month<br/>
            Each additional: $119/month
          </div>
        </div>
      );
    } else {
      // Annual pricing
      const price = 150 + (vets - 1) * 100;
      const monthlyEquivalent = price;
      const annualTotal = price * 12;
      
      return (
        <div className="space-y-1">
          <div className="text-3xl font-bold">${price}/month</div>
          <div className="text-sm text-gray-600">
            First doctor: $150/month<br/>
            Each additional: $100/month
          </div>
          <div className="text-base text-red-600 font-bold">
            Billed as ${annualTotal}/year
          </div>
        </div>
      );
    }
  };

  return (
    <div className="relative">
      <div 
        className="fixed inset-0 bg-cover bg-center bg-no-repeat blur-sm"
        style={{ backgroundImage: 'url(/blurBackground.png)', filter: 'blur(8px)' }}
      ></div>
      <div className="fixed inset-0 bg-black/30"></div>
      <div className="relative min-h-screen">
        <main className="max-w-[460px] mx-auto pt-24 pb-24 px-4">
          <div className="bg-white/80 backdrop-blur-md rounded-lg p-6 shadow-xl border border-gray-200/20">
            {/* Back Arrow - Only show on steps 2 and 3 */}
            {step > 1 && (
              <button
                onClick={handleBack}
                className="absolute text-gray-600 hover:text-gray-800 transition-colors p-2 -ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}

            {/* Step 1: Initial Sign Up */}
            {step === 1 && (
              <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                <div className="text-center mb-6">
                  <h1 className="text-2xl font-bold text-gray-800">Create Account</h1>
                  <p className="text-sm text-gray-600 mt-1">Join Lyra for Veterinary Practices</p>
                </div>
              
                <form className="space-y-4">
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="First name"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Last name"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Practice Name
                    </label>
                    <input
                      type="text"
                      name="practiceName"
                      value={formData.practiceName}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter your practice name"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter your email"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter your phone number"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Create password"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Confirm password"
                      />
                    </div>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="termsAccepted"
                      checked={formData.termsAccepted}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-[#00B8D4] border-gray-300 rounded"
                    />
                    <label className="ml-2 text-xs text-gray-700">
                      I agree to the{' '}
                      <Link to="/terms" className="text-[#00B8D4] hover:text-[#008CA1]">
                        Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link to="/privacy" className="text-[#00B8D4] hover:text-[#008CA1]">
                        Privacy Policy
                      </Link>
                    </label>
                  </div>

                  {error && (
                    <div className="mt-2">
                      {error}
                    </div>
                  )}

                  <button
                    onClick={handleNextStep}
                    disabled={step === 1 && loading}
                    className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                             hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2
                             disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {getButtonText()}
                  </button>
                </form>

                <div className="mt-4 text-center text-xs text-gray-600">
                  Already have an account?{' '}
                  <Link to="/login" className="text-[#00B8D4] hover:text-[#008CA1] font-medium">
                    Sign in
                  </Link>
                </div>
              </div>
            )}

            {/* Step 2: Setup Process List */}
            {step === 2 && (
              <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                <div className="text-center mb-6">
                  <h1 className="text-2xl font-bold text-gray-800">Setup Process</h1>
                  <p className="text-sm text-gray-600 mt-1">Here's what happens next</p>
                </div>

                <div className="space-y-8 mt-8">
                  {/* Step 1 */}
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                      1
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-800 mb-1">Complete Setup</h3>
                      <p className="text-sm text-gray-600">
                      Tell us about your clinic - we'll personalize Lyra to your practice!
                      </p>
                    </div>
                  </div>

                  {/* Step 2 */}
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                      2
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-800 mb-1">Verify Your Account</h3>
                      <p className="text-sm text-gray-600">
                        Don't forget to check your spam folder.
                      </p>
                    </div>
                  </div>

                  {/* Step 3 */}
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                      3
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-800 mb-1">Blast Off!</h3>
                      <p className="text-sm text-gray-600">
                        <span className="font-medium text-green-600">Immediate access:</span> AI Scribe, Chat, and standalone features are available right away!
                      </p>
                      <p className="text-sm text-gray-600 mt-1">
                        <span className="font-medium text-blue-600">PIMs Integration (if selected):</span> It takes 24-48 hours to complete your PIMs integration for Mission Brief and other data-connected features.
                      </p>
                    </div>
                  </div>

                  {/* Step 4 */}
                  {/* <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                      4
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-800 mb-1">Mission Complete</h3>
                      <p className="text-sm text-gray-600">
                          Enjoy everything Lyra has to offer!
                          Don't forget to check out the LyraVet iOS app!
                      </p>
                    </div>
                  </div> */}
                </div>

                <button
                  onClick={handleNextStep}
                  disabled={step === 1 && loading}
                  className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                           hover:bg-[#008CA1] transition-colors text-sm font-medium mt-8
                           disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  {getButtonText()}
                </button>
              </div>
            )}

            {/* Step 3: Demographics */}
            {step === 3 && (
              <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                <div className="text-center mb-6">
                  <h1 className="text-2xl font-bold text-gray-800">Practice Info</h1>
                  <p className="text-sm text-gray-600 mt-1">Tell us about your practice</p>
                </div>

                <form className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Street Address *
                    </label>
                    <input
                      type="text"
                      name="streetAddress"
                      value={formData.streetAddress}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter street address"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Suite/Unit (Optional)
                    </label>
                    <input
                      type="text"
                      name="suite"
                      value={formData.suite}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Suite or unit number"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Country
                      </label>
                      <CountryDropdown
                        value={country}
                        onChange={(val) => setCountry(val)}
                        classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                placeholder-gray-400 transition-colors text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        State/Province
                      </label>
                      <RegionDropdown
                        country={country}
                        value={region}
                        onChange={(val) => setRegion(val)}
                        classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                placeholder-gray-400 transition-colors text-sm"
                        disableWhenEmpty={true}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        City *
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter city"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Postal Code *
                      </label>
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        required
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter postal code"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      PIMs/EMR Provider *
                    </label>
                    <input
                      type="text"
                      name="pimsProvider"
                      value={formData.pimsProvider}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter your EMR/PIMs provider"
                    />
                  </div>

                  <button
                    onClick={handleNextStep}
                    disabled={step === 1 && loading}
                    className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                             hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2
                             disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {getButtonText()}
                  </button>
                </form>
              </div>
            )}

            {/* Step 4: Plan Selection */}
            {step === 4 && (
              <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                <div className="text-center mb-6">
                  <h1 className="text-2xl font-bold text-gray-800">Select Your Plan</h1>
                  <p className="text-sm text-gray-600 mt-1">Choose the best option for your practice</p>
                </div>

                <form className="space-y-4">
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Subscription Type
                    </label>
                    <div className="grid grid-cols-2 gap-3">
                      <button
                        type="button"
                        onClick={() => setFormData({...formData, isIntegrated: false, veterinarians: 1})}
                        className={`p-4 rounded-lg border-2 transition-colors text-left
                                  ${!formData.isIntegrated 
                                    ? 'border-[#00B8D4] bg-blue-50' 
                                    : 'border-gray-200 bg-white'}`}
                      >
                        <div className="font-semibold text-gray-800">Standard</div>
                        <div className="text-xs text-gray-600 mt-1">
                          For individual DVMs without PIMs integration
                        </div>
                      </button>
                      <button
                        type="button"
                        onClick={() => setFormData({...formData, isIntegrated: true})}
                        className={`p-4 rounded-lg border-2 transition-colors text-left
                                  ${formData.isIntegrated 
                                    ? 'border-[#00B8D4] bg-blue-50' 
                                    : 'border-gray-200 bg-white'}`}
                      >
                        <div className="font-semibold text-gray-800">Integrated</div>
                        <div className="text-xs text-gray-600 mt-1">
                          For practices with PIMs integration
                        </div>
                      </button>
                    </div>
                  </div>

                  {formData.isIntegrated && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        How many users will be using LyraVet?
                      </label>
                      <input
                        type="range"
                        min="1"
                        max="12"
                        name="veterinarians"
                        value={formData.veterinarians}
                        onChange={handleInputChange}
                        className="w-full h-2 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-[#00B8D4]"
                      />
                      <div className="flex justify-between text-xs text-gray-600 mt-1">
                        <span>1</span>
                        <span>12+</span>
                      </div>
                      <div className="text-center text-sm text-gray-600 mt-1">
                        Selected: {formData.veterinarians === 12 ? '12+' : formData.veterinarians} user{formData.veterinarians > 1 ? 's' : ''}
                      </div>
                    </div>
                  )}

                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Billing Cycle
                    </label>
                    <div className="grid grid-cols-2 gap-3">
                      <button
                        type="button"
                        onClick={() => setBillingCycle('monthly')}
                        className={`p-4 rounded-lg border-2 transition-colors text-left
                                  ${billingCycle === 'monthly' 
                                    ? 'border-[#00B8D4] bg-blue-50' 
                                    : 'border-gray-200 bg-white'}`}
                      >
                        <div className="font-semibold text-gray-800">Monthly</div>
                        <div className="text-xs text-gray-600 mt-1">
                          Pay month-to-month
                        </div>
                        <div className="mt-2 font-bold text-gray-800">
                          {!formData.isIntegrated ? '$130/month' : 
                            formData.veterinarians >= 12 ? 'Custom pricing' :
                            `$${179 + (formData.veterinarians - 1) * 119}/month`}
                        </div>
                        {formData.isIntegrated && formData.veterinarians > 1 && formData.veterinarians < 12 && (
                          <div className="text-xs text-gray-600">
                          </div>
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={() => setBillingCycle('annual')}
                        className={`p-4 rounded-lg border-2 transition-colors text-left
                                  ${billingCycle === 'annual' 
                                    ? 'border-[#00B8D4] bg-blue-50' 
                                    : 'border-gray-200 bg-white'}`}
                      >
                        <div className="font-semibold text-gray-800">Annual</div>
                        <div className="text-xs text-gray-600 mt-1">
                          Save with yearly billing
                        </div>
                        <div className="mt-2 font-bold text-gray-800">
                          {!formData.isIntegrated ? '$110/month' : 
                            formData.veterinarians >= 12 ? 'Custom pricing' :
                            `$${150 + (formData.veterinarians - 1) * 100}/month`}
                        </div>
                        {!formData.isIntegrated && (
                          <div className="text-xs text-red-600 font-bold">
                            Save $240/year
                          </div>
                        )}
                        {formData.isIntegrated && formData.veterinarians < 12 && (
                          <div className="text-xs text-red-600 font-bold">
                            Save ~16%
                          </div>
                        )}
                        {formData.isIntegrated && formData.veterinarians > 1 && formData.veterinarians < 12 && (
                          <div className="text-xs text-gray-600">
                          </div>
                        )}
                      </button>
                    </div>
                  </div>

                  {/* Enterprise pricing message */}
                  {formData.isIntegrated && formData.veterinarians >= 12 && (
                    <div className="text-center py-4">
                      <div className="space-y-2">
                        <div>Contact us directly for discounted enterprise pricing</div>
                        <div className="text-[#00B8D4]">support@lyravet.com</div>
                      </div>
                    </div>
                  )}

                  {/* <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Promo Code (Optional)
                    </label>
                    <input
                      type="text"
                      name="promoCode"
                      value={formData.promoCode || ''}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                               focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                               placeholder-gray-400 transition-colors text-sm"
                      placeholder="Enter promo code"
                    />
                  </div> */}

                  <button
                    type="submit"
                    onClick={handleNextStep}
                    disabled={loading || (formData.isIntegrated && formData.veterinarians >= 12)}
                    className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                             hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2
                             disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {loading ? 'Processing...' : (formData.isIntegrated && formData.veterinarians >= 12) ? 'Contact Sales Team' : 'Start Free Trial'}
                  </button>
                </form>

                {/* Add error message display */}
                {error && (
                  <p className="text-red-500 text-sm text-center mt-2">
                    {error}
                  </p>
                )}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SignUpPage;
